import React from "react";
import styles from "./styles.module.css";
import { strip, getReviewDateText } from "../../common/gFunctions";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

const ReviewModal = ({review,isOpen,toggleModal,showDate=true}) => {
    const title = strip("<p>"+review.title+"</p>");

    const dateText = getReviewDateText(review.date);

    return (
        <Modal isOpen={isOpen} toggle={toggleModal} className={[ "tag-modal"].join(" ")}>
            <div className={title ? "border_bottom" : ""}>
                <ModalHeader toggle={toggleModal}>
                    
                        <div className={styles.review_heading}>
                            <h4>{title}</h4>
                        </div>
                </ModalHeader>
            </div>
            <ModalBody>
                <div className="modal_inner">
                    <div className={styles.review_modla}>
                        <div className={styles.review_text}>
                            { ReactHtmlParser(review.content.replace(/mprofit/gi, "MProfit")) }
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className={"review-modal"}>
                <div>
                    <div className={styles.modalDesignation}>
                        { ReactHtmlParser(review.excerpt) }
                    </div>
                    { showDate && <div className={styles.months}>{dateText}</div> }
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default ReviewModal