import React, { useState } from "react";
import styles from "./styles.module.css";
import { strip, getReviewDateText } from "../../common/gFunctions";
import ReactHtmlParser from 'react-html-parser';

const ReviewItem = ({review, readMore=()=>{}, showDate=true}) => {

    const [showReadMore, setShowReadMore] = useState(false);

    const dateText = getReviewDateText(review.date);

    const updateReadMore = (el) => {
        if (el) {
            setShowReadMore(el.offsetHeight<el.scrollHeight);
        }
    }

    return (
        <div className={styles.review_box}>
            <div className={styles.review_heading}>
                <h6>{strip("<p>"+review.title+"</p>")}</h6>
            </div>
            
            <div className={[styles.review_text, showReadMore ? styles.boxOp : ""].join(" ")} ref={(el) => updateReadMore(el)}>
                { ReactHtmlParser(review.content.replace(/mprofit/gi, "MProfit")) }
            </div>
            <div className={styles.readMoreCo}>
                { showReadMore  ?  
                    <div onClick={() => readMore(review)}> <span className={styles.read} >Read more</span> </div>
                    : ""   
                }
            </div>
            <div>
                <div className={styles.designation}>
                    { ReactHtmlParser(review.excerpt) }
                </div>
                {/* <div className={styles.designation}>
                    {strip(review.excerpt)}
                </div> */}
                {/* { showDate && <div className={styles.review_date}> <span className="mon">{dateText}</span> </div> } */}
            </div>
        </div>
    )
}

export default ReviewItem